import React from "react";
import { SectionTwoColumns } from "./common";

const AboutUs = () => {
  return (
    <SectionTwoColumns
      src="https://youtu.be/iQMiab3-RWY"
      video
      title="O nas"
      idSection="onas"
    >
      <div>
        <hr className="line" />
        <p className="grey-text">
          Krynica-Zdrój to znana miejscowość uzdrowiskowo-wypoczynkowa.
          Jest malowniczo położona w Beskidzie Sądeckim, na południowych, nasłonecznionych stokach.
          To stwarza specyficzny mikroklimat, który charakteryzuje się znacznymi różnicami
          temperatur między dniem i nocą. Pokrywa śnieżna utrzymuje się tutaj przez długi czas
          (najczęściej od połowy listopada, aż do końca marca). Właśnie dlatego Krynica-Zdrój
          kojarzy się z nartami, snowboardem i innymi sportami zimowymi. Jednak również latem
          jest mnóstwo możliwości spędzania wolnego czasu. Wczasy w Krynicy-Zdroju są doskonałym
          pomysłem dla wszystkich fanów turystyki.
          </p>
        <p className="grey-text">
          Bogactwem naturalnym miasta są wody mineralne. Od lat właściwości lecznicze tych
          wód są powszechnie znane i cenione. Wysoka wartość lecznicza i ekonomiczna krynickich
          wód mineralnych jest bogactwem kraju. Zatrzymując się w naszych apartamentach w
          Krynicy-Zdroju, będziesz mógł ich skosztować w takich pijalniach, jak: „Pijalnia
          Główna”, „Mieczysław”, „Słotwinka” oraz „Jana”.
          </p>
      </div>
    </SectionTwoColumns>
  );
}

export default AboutUs;