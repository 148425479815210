import { MDBContainer, MDBRow, MDBCol } from "mdbreact";
import React, { useEffect } from "react";
import { useInView } from 'react-intersection-observer';

const SectionTwoColumns = ({ title, src, children, video, idSection }) => {
  const [ref, inView] = useInView({
    threshold: 0.5,
  })

  /* useEffect(() => {
     if (video) {
       var vid = document.getElementById("myVideo");
       if (inView) {
         vid.play();
         vid.requestPictureInPicture().catch(e => console.log(e));
 
       } else {
         vid.pause();
       }
     }
   }, [inView])*/

  return (
    <MDBContainer>
      <section id={idSection} className="mb-5 pt-5">
        <h5 className="text-center text-uppercase font-weight-bold spacing mb-5 py-4">{title}</h5>
        <MDBRow>
          <MDBCol md="5" className="d-flex flex-column justify-content-center">
            <div ref={ref}>
              {video ?
                <iframe id="myVideo" className="w-100" height="315" src="https://www.youtube.com/embed/iQMiab3-RWY"
                  frameborder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowfullscreen>

                </iframe>
                : <img className="w-100 rounded shadow-lg" src={src} />
              }
            </div>
          </MDBCol>
          <MDBCol md="6" className="justify-content-left ml-lg-5">
            {children}
          </MDBCol>
        </MDBRow >
      </section>
    </MDBContainer>
  );
}

export default SectionTwoColumns;