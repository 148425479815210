import React, { useCallback, useState } from "react";
import { SectionOneColumn } from "./common";
import Carousel, { Modal, ModalGateway } from "react-images";
import Gallery from "react-photo-gallery";

const MyGallery = () => {
  const [currentImage, setCurrentImage] = useState(0);
  const [viewerIsOpen, setViewerIsOpen] = useState(false);
  const openLightbox = useCallback((event, { photo, index }) => {
    setCurrentImage(index);
    setViewerIsOpen(true);
  }, []);

  const closeLightbox = () => {
    setCurrentImage(0);
    setViewerIsOpen(false);
  };
  const photos = [
    {
      //1
      src: "../../../../img/galeria1.JPG",
      width: 4,
      height: 3
    },
    {
      //2
      src: "../../../../img/galeria4.JPG",
      width: 4,
      height: 3
    },
    {
      //3
      src: "../../../../img/galeria5.JPG",
      width: 4,
      height: 3
    },
    {
      //4
      src: "../../../../img/galeria2.JPG",
      width: 3,
      height: 2
    },
    {
      //5
      src: "../../../../img/galeria3.JPG",
      width: 3,
      height: 2
    },
    {
      //6
      src: "../../../../img/apartament_1.JPG",
      width: 4,
      height: 3
    },
    {
      //7
      src: "../../../../img/apartament_2.JPG",
      width: 4,
      height: 3
    },
    {
      //8
      src: "../../../../img/galeria6.JPG",
      width: 4,
      height: 3
    },
    {
      //9
      src: "../../../../img/galeria7.JPG",
      width: 4,
      height: 3
    },
    {
      //10
      src: "../../../../img/galeria8.JPG",
      width: 3,
      height: 4
    },
    {
      //11
      src: "../../../../img/galeria9.JPG",
      width: 3,
      height: 4
    },
    {
      //12
      src: "../../../../img/galeria10.JPG",
      width: 3,
      height: 4
    },
    {
      //13
      src: "../../../../img/galeria11.JPG",
      width: 3,
      height: 4
    },
    {
      //14
      src: "../../../../img/galeria12.JPG",
      width: 3,
      height: 4
    },
    {
      //15
      src: "../../../../img/galeria13.JPG",
      width: 4,
      height: 3
    },
    {
      //16
      src: "../../../../img/galeria14.JPG",
      width: 4,
      height: 3
    },
    {
      //17
      src: "../../../../img/galeria15.JPG",
      width: 3,
      height: 4
    },
    {
      //18
      src: "../../../../img/galeria16.JPG",
      width: 3,
      height: 4
    },
    {
      //18
      src: "../../../../img/galeria17.JPG",
      width: 5,
      height: 9
    }
  ];
  return (
    <SectionOneColumn
      idSection="galeria"
      title="Galeria">
      <div>
        <Gallery photos={photos} onClick={openLightbox} />
        <ModalGateway>
          {viewerIsOpen ? (
            <Modal onClose={closeLightbox}>
              <Carousel
                currentIndex={currentImage}
                views={photos.map(x => ({
                  ...x,
                  srcset: x.srcSet,
                  caption: x.title
                }))}
              />
            </Modal>
          ) : null}
        </ModalGateway>
      </div>
    </SectionOneColumn>
  );
}

export default MyGallery;