import { MDBContainer, MDBCardGroup, MDBCard, MDBCardBody, MDBCardHeader, MDBIcon, MDBCardTitle, MDBCardText } from "mdbreact";
import React from "react";

const Offer = () => {
  const offers = [
    {
      icon: "user",
      title: "Osoby indywidualne",
      description: "Zabierz siebie, rodzinę i bliskich na wymarzony wypoczynek w samym sercu Beskidu Sądeckiego. W dolinie potoku, która jest otoczona malowniczymi wzgórzami, zawsze znajdziesz wyśmienitą rozrywkę dla swoich najbliższych."
    },
    {
      icon: "users",
      title: "Grupy zorganizowane",
      description: "Nasza oferta skierowana jest też dla większych grup zorganizowanych dla których mamy możliwość zorganizowania wyżywienia.Dzięki ilości 45 dostępnych miejsc grupa naprawdę może być spora."
    }
  ];
  return (
    <MDBContainer fluid className="light-grey-background">
      <MDBContainer className="pb-4">
        <section id="oferta" className="mt-3 pt-4 mb-3 pb-3">
          <h5 className="text-center text-uppercase font-weight-bold spacing my-5 pt-2 pb-4">Nasza oferta</h5>
          <MDBCardGroup deck>
            {
              offers.map(offer => (
                <MDBCard className="rgba-white-light">
                  <MDBCardBody>
                    <MDBCardHeader className="border-0 text-center" transparent>
                      <MDBIcon className="dark-grey-text" icon={offer.icon} size="3x" />
                    </MDBCardHeader>
                    <MDBCardTitle className="text-center spacing font-weight-bold text-uppercase dark-grey-text mb-4" tag="p">{offer.title}</MDBCardTitle>
                    <MDBCardText className="text-center dark-grey-text font-small">
                      {offer.description}
                    </MDBCardText>
                  </MDBCardBody>
                </MDBCard>
              ))
            }
          </MDBCardGroup>
        </section>
      </MDBContainer>
    </MDBContainer>
  )
}

export default Offer;