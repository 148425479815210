import { MDBContainer } from "mdbreact";
import React from "react";

const SectionOneColumn = ({ title, children, idSection }) => {
  return (
    <MDBContainer id={idSection}>
      <section className="mb-5 pt-5">
        <h5 className="text-center text-uppercase font-weight-bold spacing mb-5 py-4">{title}</h5>
        <div>
          {children}
        </div>
      </section>
    </MDBContainer>
  );
}

export default SectionOneColumn;