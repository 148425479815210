import { MDBRow, MDBCol, MDBIcon } from "mdbreact";
import React from "react";
import { SectionTwoColumns } from "./common";

const Attractions = () => {
  return (
    <SectionTwoColumns
      src="../../../../img/kolej.jpg"
      title="Atrakcje"
      idSection="atrakcje"
    >
      <p className="grey-text mt-sm-4">
        Jako pensjonat Mirex proponujemy swoim gościom wiele atrakcji.
      </p>
      <MDBRow>
        <MDBCol size="12" className="d-flex">
          <MDBIcon className="text-info" icon="camera-retro" size="2x" />
          <h6 className="font-weight-bold text-info d-flex align-items-center m-0 ml-3">Lista atrakcji</h6>
        </MDBCol>
        <MDBCol size="6">
          <ul>
            <li>Kolej Gondolowa</li>
            <li>Kolejka na Górę Parkową</li>
            <li>Sankostrada</li>
            <li>Lodowisko</li>
            <li>Szkoła Snowboardu</li>
            <li>Paralotnie</li>
            <li>Szkoła narciarska</li>
          </ul>
        </MDBCol>
        <MDBCol size="6">
          <ul>
            <li>Turystyka piesza i rowerowa</li>
            <li>Słotwiny</li>
            <li>Jaworzyna Krynicka</li>
            <li>Wierchomla</li>
            <li>Czarny Potok</li>
            <li>Tylicz</li>
          </ul>
        </MDBCol>
      </MDBRow>
    </SectionTwoColumns>
  );
}

export default Attractions;