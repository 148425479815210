import './App.css';
import '@fortawesome/fontawesome-free/css/all.min.css';
import 'bootstrap-css-only/css/bootstrap.min.css';
import './assets/scss/mdb-free.scss';
import { Navbar } from './components/Navbar';
import { HomePage } from './views/HomePage';

function App() {
  return (
      <div style={{overflowX: "hidden"}}>
      <Navbar />
      <HomePage />
    </div>
  );
}

export default App;
