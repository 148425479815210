import { MDBFooter, MDBContainer, MDBRow, MDBCol, MDBIcon } from "mdbreact";
import React from "react";

const Footer = () => {
  return (
    <MDBFooter style={{ backgroundColor: "#eceff1" }} className="font-small mt-4">
      <MDBRow>
        <MDBCol md="12">
          <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2595.0545883725476!2d20.9529853156602!3d49.426781979347176!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x473e76b38e9cf2f9%3A0x40af0088d6107b1d!2sMirex!5e0!3m2!1spl!2spl!4v1610712979813!5m2!1spl!2spl"
            className="w-100 h-100"
            style={{ minHeight: "300px" }}
            frameborder="0"
            allowfullscreen=""
            aria-hidden="false"
            tabindex="0" />
        </MDBCol>
      </MDBRow>
      <div style={{ backgroundColor: "#72BDE5" }}>
        <MDBContainer>
          <MDBRow className="py-4 d-flex align-items-center justify-content-center">
            <MDBCol className="md-5 text-left  mb-md-0">
              <h6 className="mb-0 white-text text-center text-md-left">
                <strong>
                  Odwiedź nas w mediach społecznościowych
                  </strong>
              </h6>
            </MDBCol>
            <MDBCol className="md-7 text-center text-md-right">
              <a href="https://www.facebook.com/Noclegi-Mirex-106044496516633" target="_blank">
                <MDBIcon icon="facebook-f" size="2x" />
              </a>
            </MDBCol>
          </MDBRow>
        </MDBContainer>
      </div>
      <MDBContainer fluid className="text-center text-md-left mt-5 mb-4">
        <MDBRow className="d-flex justify-content-center dark-grey-text">
          <MDBCol md="3"></MDBCol>
          <MDBCol md="3" className="mb-4">
            <div>
              <h6 className="text-uppercase font-weight-bold">Kontakt</h6>
              <hr style={{ width: "60px" }} className="cyan mb-4 mt-0 d-inline-block mx-auto" />
              <div className="d-flex flex-column align-items-md-start align-items-center">
                <MDBRow>
                  <MDBCol xs="4" className="mr-3">
                    <MDBIcon icon="home" />
                  </MDBCol>
                  <MDBCol className="font-weight-bold" xs="8">
                    33-380 Krynica Zdrój <br />ul. Piłsudskiego 50
                </MDBCol>
                </MDBRow>
                <MDBRow className="dark-grey-text mt-3" tag="a" href="tel:+48 503 014 667">
                  <MDBCol xs="4" className="mr-3">
                    <MDBIcon icon="mobile" />
                  </MDBCol>
                  <MDBCol className="font-weight-bold" xs="8">
                    +48 503 014 667
                </MDBCol>
                </MDBRow>
                <MDBRow className="dark-grey-text mt-3" tag="a" href="tel:018 471 61 88">
                  <MDBCol xs="4" className="mr-3">
                    <MDBIcon icon="phone" />
                  </MDBCol>
                  <MDBCol className="font-weight-bold" xs="8">
                    018 471 61 88
                </MDBCol>
                </MDBRow>
                <MDBRow className="dark-grey-text mt-3" tag="a" href="mailto:biuro@mirex.info.pl">
                  <MDBCol xs="4" className="mr-3">
                    <MDBIcon icon="at" />
                  </MDBCol>
                  <MDBCol className="font-weight-bold" xs="8">
                    biuro@mirex.info.pl
                </MDBCol>
                </MDBRow>
              </div>
            </div>
          </MDBCol>
          <MDBCol md="3" className="mb-4">
            <div>
              <h6 className="text-uppercase font-weight-bold">Dane do przelewu</h6>
              <hr style={{ width: "60px" }} className="cyan mb-4 mt-0 d-inline-block mx-auto" />
              <div className="d-flex flex-column align-items-md-start align-items-center">
                <MDBRow>
                  <MDBCol xs="4" className="mr-3">
                    <MDBIcon icon="user" />
                  </MDBCol>
                  <MDBCol className="font-weight-bold" xs="8">
                    Sylwia Gargula
                </MDBCol>
                </MDBRow>
                <MDBRow className="dark-grey-text mt-3" tag="a" href="tel:+48 503 014 667">
                  <MDBCol xs="4" className="mr-3">
                    <MDBIcon icon="home" />
                  </MDBCol>
                  <MDBCol className="font-weight-bold" xs="8">
                    33-300 Nowy Sącz <br />ul. Węgierska 18
                </MDBCol>
                </MDBRow>
                <MDBRow className="dark-grey-text mt-3" tag="a" href="tel:018 471 61 88">
                  <MDBCol xs="4" className="mr-3">
                    <MDBIcon icon="university" />
                  </MDBCol>
                  <MDBCol className="font-weight-bold" xs="8">
                    Łącki Bank Spółdzielczy <br />oddział w Nowym Sączu
                </MDBCol>
                </MDBRow>
                <MDBRow className="dark-grey-text mt-3" tag="a" href="mailto:biuro@mirex.info.pl">
                  <MDBCol xs="4" className="mr-3">
                    <MDBIcon icon="key" />
                  </MDBCol>
                  <MDBCol className="font-weight-bold" xs="8">
                    57 8805 0009 0017 6271 2011 0001
                </MDBCol>
                </MDBRow>
              </div>
            </div>
          </MDBCol>
          <MDBCol md="3"></MDBCol>
        </MDBRow>
      </MDBContainer >
      <div className="footer-copyright text-center py-3">
        <MDBContainer className="text-white font-weight-bold" fluid>
          &copy; 2021 Copyright: mirex.info.pl
        </MDBContainer>
      </div>
    </MDBFooter >
  );
}

export default Footer;