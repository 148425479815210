import React, { createContext, useEffect, useRef } from "react";
import videojs from "video.js";

function VideoPlayer(props) {
    const playerRef = useRef();

    useEffect(() => {
        const player = videojs(playerRef.current, props);
        props.setPlayer(player);
        return () => {
            player.dispose();
        };
    }, []);

    return (

        <div style={{ width: "100%", height: "auto" }} data-vjs-player>
            <video style={{ width: "100%", height: "auto" }} ref={playerRef} className="video-js" playsInline />
        </div>

    );
}

export default VideoPlayer;