import React, { useState } from "react";
import Rating from "react-rating";
import { SectionOneColumn } from "./common";
import 'font-awesome/css/font-awesome.min.css';
import { MDBIcon, MDBBtn, MDBModal, MDBModalHeader, MDBModalBody, MDBModalFooter } from "mdbreact";
import Carousel from "react-multi-carousel";

const AddOpinionModal = ({ isOpen, close }) => {
  const [assessment, setAssessment] = useState(0);
  const [content, setContent] = useState("");
  const [sending, setSending] = useState(false);
  const [success, setSuccess] = useState(false);
  const [failed, setFailed] = useState(false);
  const closeModal = () => {
    setAssessment(0);
    setContent("");
    setSending(false);
    setSuccess(false);
    setFailed(false);
    close();
  }

  const sendOpinion = () => {
    setSending(true);
    const model = {
      assessment,
      content
    }
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(model)
    }
    return fetch("/api/contact/opinion", requestOptions)
      .then(resp => {
        if (resp.status === 200) {
          setSuccess(true);
          setFailed(false);
          setSending(false);
        } else {
          setSuccess(false);
          setFailed(true);
          setSending(false);
        }
      }).catch(e => {
        setSuccess(false);
        setFailed(true);
        setSending(false);
      });
  }

  const addModal = () => (
    <>
      <MDBModalHeader>Twoja opinia</MDBModalHeader>
      <MDBModalBody>
        <div className="text-center">
          <Rating
            emptySymbol={'fa fa-star-o fa-2x orange-text'}
            fullSymbol={'fa fa-star fa-2x low orange-text'}
            placeholderSymbol={'fa fa-star fa-2x low orange-text'}
            placeholderRating={assessment}
            fractions={2}
            onChange={value => setAssessment(value)}
          />
        </div>
        <div className="form-group">
          <label className="font-small grey-text" htmlFor="exampleInput">Opinia</label>
          <textarea defaultValue={content} onChange={e => setContent(e.target.value)} id="exampleInput" className="form-control" />
        </div>
      </MDBModalBody>
      <MDBModalFooter>
        <MDBBtn size="sm" disabled={sending} onClick={() => closeModal()} color="secondary">Anuluj</MDBBtn>
        {sending ?
          <MDBBtn className="m-0 rounded" disabled={true} type="button" size="sm" color="info">
            Wysyłanie
            <div style={{ height: "1rem", width: "1rem" }} className="spinner-border ml-3" role="status">
            </div>
          </MDBBtn> :
          <MDBBtn size="sm" onClick={() => sendOpinion()} color="primary">Wyślij</MDBBtn>
        }
      </MDBModalFooter>
    </>
  )

  const successModal = () => (
    <>
      <MDBModalHeader style={{ backgroundColor: "#d4edda" }}>Twoja opinia zaostała wysłana</MDBModalHeader>
      <MDBModalBody style={{ backgroundColor: "#d4edda" }}>
        Dziękujemy za podzielenie się swoją opinią, dzięki której możemy jeszcze bardziej udoskonalić nasz Pensjonat.
        Dziękujemy za skorzystanie z naszych usług i zapraszamy ponownie.
      </MDBModalBody>
      <MDBModalFooter style={{ backgroundColor: "#d4edda" }}>
        <MDBBtn size="sm" disabled={sending} onClick={() => closeModal()} color="secondary">Ok</MDBBtn>
      </MDBModalFooter>
    </>
  )

  const failedModal = () => (
    <>
      <MDBModalHeader style={{ backgroundColor: "#f8d7da" }}>Nie udało się wysłać opini</MDBModalHeader>
      <MDBModalBody style={{ backgroundColor: "#f8d7da" }}>
        Przepraszamy za powstały problem. Spróbuj wysłać opinie ponownie lub wróć do nas w przyszłości.
      </MDBModalBody>
      <MDBModalFooter style={{ backgroundColor: "#f8d7da" }}>
        <MDBBtn size="sm" disabled={sending} onClick={() => closeModal()} color="secondary">Anuluj</MDBBtn>
        <MDBBtn size="sm" onClick={() => {
          setFailed(false);
        }} color="primary">Spróbuj ponownie</MDBBtn>
      </MDBModalFooter>
    </>
  )

  return (
    <MDBModal isOpen={isOpen}>
      {!success && !failed ? addModal() : success ? successModal() : failedModal()}
    </MDBModal >
  );
}

const Opinions = () => {
  const [isOpen, setIsOpen] = useState(false);
  const responsive = {
    superLargeDesktop: {
      breakpoint: { max: 4000, min: 3000 },
      items: 5
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1
    }
  };

  const opinions = [
    {
      name: "Marcin",
      ratings: 5,
      description: "Lokalizacja rewelacyjna dla mojego celu podróży. Względnie nowy lokal.Wyposażony we wszystkie potrzebne urządzenia i sprzęt."
    },
    {
      name: "Robert M.",
      ratings: 5,
      description: "Doskonała lokalizacja i bardzo mili gospodarze. Pozdrawiam z rodziną ;)"
    },
    {
      name: "Łukasz K.",
      ratings: 5,
      description: "Zdecydowanie polecam. Pracują tam same złote osoby, przesympatyczni i kochani. Pani uściskała nas po zakończeniu pobytu, zamieniła kilka zdań i do tego wręczyła miły upominek. Czysto, schludnie, cicho i do tego przytulnie. W pokoju była czysta i pachnąca pościel w idealnym stanie. Stosunek ceny do jakości jest idealny! Serdecznie polecamy i już myślimy nad kolejną rezerwacją."
    },
    {
      name: "Ania",
      ratings: 5,
      description: "Potwierdzam wszystkie pozytywne komantarze, opinie i zachwyty nad tym miejscem. Pensjonat znakomicie położony, w spokojnym zakątku. Miejsce bardzo gustowne i praktycznie urządzone, przyjazne zarówno dla rodzin z dziećmi, dla podróżujących w pojedynkę oraz grup zorganizowanych. Polecam!!!"
    },
    {
      name: "Gość apartamentu",
      ratings: 5,
      description: "Czystość, jakość apartamentu oraz wyposażenie dodatkowe zdecydowanie na plus."
    }
  ]

  const Opinion = (opinion) => {
    return (
      <div>
        <h4 className="font-weight-bold text-center dark-grey-text mt-4">
          {opinion.name}
        </h4>
        <p><MDBIcon icon='quote-left' />{opinion.description}</p>
        <div>
          <Rating
            emptySymbol={'fa fa-star-o fa-2x orange-text'}
            fullSymbol={'fa fa-star fa-2x low orange-text'}
            placeholderSymbol={'fa fa-star fa-2x low orange-text'}
            placeholderRating={opinion.ratings}
            readonly
          />
        </div>
      </div>
    );
  }

  return (
    <SectionOneColumn
      title="Opinie klientów"
      idSection="opinie"
    >
      <Carousel responsive={responsive}>
        {opinions.map(opinion => (
          Opinion(opinion)
        ))}
      </Carousel>
      <div className="text-center mt-5">
        <MDBBtn
          className="m-0 rounded"
          onClick={() => setIsOpen(true)}
          size="sm"
          color="info">
          Wyślij swoją opinię
        </MDBBtn>
      </div>
      <AddOpinionModal
        isOpen={isOpen}
        close={() => setIsOpen(false)}
      />
    </SectionOneColumn>
  )
}

export default Opinions;