import { MDBCol, MDBBadge } from "mdbreact";
import React from "react";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { SectionOneColumn } from "./common";
import 'font-awesome/css/font-awesome.min.css';

const OurRooms = () => {
  const amenties =
  {
    "Wi-Fi": "fas fa-wifi",
    "TV": "fas fa-tv",
    "Kuchnia": "fas fa-sink",
    "Łazienka": "fas fa-shower"
  };
  const rooms = [
    {
      quantity: 1,
      name: "Apartament",
      beds: 2,
      price: "200 - 350",
      description: "Duży apartament, w którym znajdują się dwa pokojnie, kuchnia oraz łazienka z kominkiem.",
      amenties: ["Wi-Fi", "TV", "Kuchnia", "Łazienka"],
      image: "../../../../img/apartament_1.jpg"
    },
    {
      quantity: 9,
      name: "Pokój jednoosobowy",
      price: "60 - 100",
      description: "Komfortowy pokój jednoosobowy, w którym gwarantowana jest czystość, cisza i spokój.",
      amenties: ["Wi-Fi", "TV", "Łazienka"],
      image: "../../../../img/pokoj_1.jpg"
    },
    {
      quantity: 9,
      name: "Pokój dwuosobowy",
      price: "100 - 160",
      description: "W naszej ofercie znajdują się pokoje dwuuosobowe z dwoma lub jednym łóżkiem.",
      amenties: ["Wi-Fi", "TV", "Łazienka"],
      image: "../../../../img/pokoj_2.jpg"
    },
    {
      quantity: 4,
      name: "Pokój trzyosobowy",
      price: "135 - 210",
      description: "Pokoje trzyosobowe wyposażone są w łóżko dwuosobowe oraz jedno łózko jednoosobowe.",
      amenties: ["Wi-Fi", "TV", "Łazienka"],
      image: "../../../../img/pokoj_3.jpg"
    },
    {
      quantity: 3,
      name: "Pokój czteroosobowy",
      price: "200 - 300",
      description: "W pokojach czteroosobowych znajdują się dwa łóżka dwuosobowe.",
      amenties: ["Wi-Fi", "TV", "Łazienka"],
      image: "../../../../img/pokoj_4.jpg"
    }
  ]
  const responsive = {
    superLargeDesktop: {
      breakpoint: { max: 4000, min: 3000 },
      items: 5
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1
    }
  };
  const card = (room) => {
    return (
      <MDBCol>
        <img src={room.image} className="rounded w-100" />
        <p style={{ fontSize: "0.9rem" }} className="text-uppercase font-weight-bold dark-grey-text spacing mt-3 mb-0">{room.name}</p>
        <MDBBadge className="py-2 px-2 mr-2" color="info">{room.price} zł</MDBBadge>
        <MDBBadge className="py-2 px-2" color="info">Ilość: {room.quantity}</MDBBadge>
      </MDBCol>
      /*
      <MDBCol className="mb-4" style={{ maxWidth: "22rem", height: "99%" }}>
        <MDBCard style={{ height: "99%" }}>
          <MDBCardImage className="img-fluid" src={room.image}
            waves />
          <MDBCardBody>
            <h5 className="font-weight-bold text-uppercase"><strong>{room.name}</strong></h5>
            <MDBBadge className="py-2 px-2" color="info">{room.price} zł</MDBBadge>
            <MDBCardText className="grey-text mt-2">{room.description}</MDBCardText>
            <MDBRow>
              {room.amenties.map(a => (
                <MDBCol className="text-center" lg="6" md="12"><i className={`${amenties[a]} dark-grey-text`}></i>{` ${a}`}</MDBCol>
              ))
              }
            </MDBRow>
          </MDBCardBody>
        </MDBCard>
      </MDBCol > 
            */
    );
  }

  return (
    <SectionOneColumn
      title="Nasze pokoje"
      idSection="pokoje"
    >
      <div>
        <p className="grey-text text-center">
          Staramy się aby każdy z naszych gości czuł sie swobodnie, jak w domu. Dlatego każdy, kto do nas przyjeżdza ma
          dostęp do Wi-Fi. W każdym pokoju znajduję się TV oraz prywatna łazienka. Do dyspozycji gości jest wspólna kuchnia,
          która znajduje się na dole Pansjonatu Mirex. W apartamencie znajduje się prywatna kuchnia przeznaczona tylko i wyłącznie
          dla jego gości.
        </p>
        <Carousel responsive={responsive}>
          {
            rooms.map(room => card(room))
          }
        </Carousel>
      </div>
    </SectionOneColumn>
  )
}

export default OurRooms;