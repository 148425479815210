import React from "react";
import { AboutUs, Attractions, ContactForm, Intro, Offer, OurRooms, Map, Footer, Opinions, MyGallery } from "./components";

const HomePage = () => {
  return (
    <div>
      <Intro />
      <AboutUs />
      <Offer />
      <OurRooms />
      <hr className="mt-5" />
      <Attractions />
      <hr className="mt-5" />
      <MyGallery />
      <hr className="mt-5" />
      <ContactForm />
      <Opinions />
      <Footer />
    </div>
  );
}

export default HomePage;