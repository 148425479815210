import React, { useState } from "react";
import { MDBNavbar, MDBNavbarBrand, MDBNavbarNav, MDBNavbarToggler, MDBCollapse, MDBNavItem, MDBNavLink, MDBContainer, MDBMask, MDBView } from 'mdbreact';
import { BrowserRouter as Router } from 'react-router-dom';

const Navbar = () => {
  const [collapse, setCollapse] = useState(false);

  function scrollToSection(sectionId) {
    var ter = document.getElementById(sectionId);
    ter.scrollIntoView({ behavior: "smooth" });
  }
  return (
    <Router>
      <MDBNavbar color="bg-primary" fixed="top" dark expand="md" scrolling transparent>
        <MDBContainer>
          <MDBNavbarBrand href="/">
            <strong>Mirex</strong>
          </MDBNavbarBrand>
          <MDBNavbarToggler onClick={() => setCollapse(!collapse)} />
          <MDBCollapse isOpen={collapse} navbar>
            <MDBNavbarNav left>
              <MDBNavItem>
                <MDBNavLink onClick={() => scrollToSection("onas")} to="#">O nas</MDBNavLink>
              </MDBNavItem>
              <MDBNavItem>
                <MDBNavLink onClick={() => scrollToSection("oferta")} to="#">Nasza oferta</MDBNavLink>
              </MDBNavItem>
              <MDBNavItem>
                <MDBNavLink onClick={() => scrollToSection("pokoje")} to="#">Nasze pokoje</MDBNavLink>
              </MDBNavItem>
              <MDBNavItem>
                <MDBNavLink onClick={() => scrollToSection("atrakcje")} to="#">Atrakcje</MDBNavLink>
              </MDBNavItem>
              <MDBNavItem>
                <MDBNavLink onClick={() => scrollToSection("galeria")} to="#">Galeria</MDBNavLink>
              </MDBNavItem>
              <MDBNavItem>
                <MDBNavLink onClick={() => scrollToSection("kontakt")} to="#">Kontakt</MDBNavLink>
              </MDBNavItem>
              <MDBNavItem>
                <MDBNavLink onClick={() => scrollToSection("opinie")} to="#">Opinie</MDBNavLink>
              </MDBNavItem>
            </MDBNavbarNav>
          </MDBCollapse>
        </MDBContainer>
      </MDBNavbar>
    </Router>
  );
}

export default Navbar;