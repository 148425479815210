import { MDBContainer, MDBRow, MDBCol, MDBInputGroup, MDBBtn, MDBAlert } from "mdbreact";
import React, { useState } from "react";
import { SectionOneColumn } from "./common";

const ContactForm = () => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [content, setContent] = useState("");
  const [success, setSuccess] = useState(false);
  const [failed, setFailed] = useState(false);
  const [sending, setSending] = useState(false);

  const sendForm = () => {
    setSending(true);
    const model = {
      name,
      email,
      phone,
      content
    }
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(model)
    }
    return fetch("/api/contact/contactForm", requestOptions)
      .then(resp => {
        if (resp.status === 200) {
          setSuccess(true);
          setFailed(false);
          setSending(false);
        } else {
          setSuccess(false);
          setFailed(true);
          setSending(false);
        }
      }).catch(() => {
        setSuccess(false);
        setFailed(true);
        setSending(false);
      });
  }

  return (
    <SectionOneColumn
      title="Formularz kontaktowy"
      idSection="kontakt"
    >
      <MDBContainer>
        {success &&
          <MDBAlert onClose={() => setSuccess(false)} color="success" dismiss>
            <strong>Gratulacje!</strong> Dziękujemy za wysłanie wiadomości. Niezwłocznie się z Tobą skontaktujemy.
        </MDBAlert>
        }
        {failed &&
          <MDBAlert onClose={() => setFailed(false)} color="danger" dismiss>
            <strong>Coś poszło nie tak!</strong> Wiadomość nie została wysłana - spróbuj ponownie.
        </MDBAlert>
        }
        <MDBRow>
          <MDBCol md="12" lg="4">
            <MDBInputGroup onChange={e => setName(e.target.value)} containerClassName="mb-3" prepend="1" hint="Imię" />
          </MDBCol>
          <MDBCol md="12" lg="4">
            <MDBInputGroup onChange={e => setEmail(e.target.value)} containerClassName="mb-3" prepend="2" hint="E-mail" />
          </MDBCol>
          <MDBCol md="12" lg="4">
            <MDBInputGroup onChange={e => setPhone(e.target.value)} containerClassName="mb-3" prepend="3" hint="Telefon" />
          </MDBCol>
        </MDBRow>
        <MDBRow>
          <MDBCol md="12" lg="12">
            <div className="form-group">
              <label className="font-small grey-text" htmlFor="exampleInput">Wiadomość</label>
              <textarea onChange={e => setContent(e.target.value)} id="exampleInput" className="form-control" />
            </div>
          </MDBCol>
        </MDBRow>
        {sending ?
          <MDBBtn className="m-0 rounded" disabled={true} type="button" size="sm" color="info">
            Wysyłanie
            <div style={{ height: "1rem", width: "1rem" }} className="spinner-border ml-3" role="status">
            </div>
          </MDBBtn>
          :
          <MDBBtn onClick={() => sendForm()} className="m-0 rounded" type="button" size="sm" color="info">Wyślij</MDBBtn>
        }
      </MDBContainer>
    </SectionOneColumn>
  );
}

export default ContactForm;